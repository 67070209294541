export function handleNewOptions(radios, optionFilter) {
  let newOptions = {};

  if (radios.type === 'Webapp') {
    return (newOptions = {
      ...optionFilter,
      service: [1, 0, 0],
      budget: [0, 1, 1, 1],
    });
  }
  if (radios.service === 'Development' || radios.service === 'Design & dev') {
    return (newOptions = {
      ...optionFilter,
      type: [1, 0, 1],
      budget: [0, 1, 1, 1],
    });
  }
  if (radios.budget === 'Less than 2k') {
    return (newOptions = {
      ...optionFilter,
      type: [1, 0, 1],
      service: [1, 0, 0],
    });
  }

  return (newOptions = {
    type: [1, 1, 1],
    service: [1, 1, 1],
    budget: [1, 1, 1, 1],
  });
}
