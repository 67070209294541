import React, { useEffect, useState } from 'react';
import { handleNewOptions } from '../uitilities/inputFilters';
import Arrow from '../images/theme2/ico-arrow.svg';

export default function ContactForm({ theme }) {
  const initialRadios = {
    type: null,
    service: null,
    budget: null,
  };
  const initialOptions = {
    type: [1, 1, 1],
    service: [1, 1, 1],
    budget: [1, 1, 1, 1],
  };
  const [radios, setRadios] = useState(initialRadios);
  const [optionFilter, setOptionFilter] = useState(initialOptions);
  // const [subject, setSubject] = useState('');
  const [clientName, setClientName] = useState('');

  const handleType = (e) => {
    setRadios({ ...radios, type: e.target.value });
  };
  const handleService = (e) => {
    setRadios({ ...radios, service: e.target.value });
  };
  const handleBudget = (e) => {
    setRadios({ ...radios, budget: e.target.value });
  };

  useEffect(() => {
    setOptionFilter(handleNewOptions(radios, optionFilter));
  }, [radios]);

  return (
    <section className={`contact-form-sec ${theme}`}>
      <div className="wrapper col-grid">
        <div className="empty" />
        <div className="form-content">
          <form
            method="POST"
            action="https://submit-form.com/0VJaO1uw"
            className="form"
          >
            <input
              type="hidden"
              name="_redirect"
              value="https://rekhchandsahu.com/success"
            />
            <input type="hidden" name="_append" value="false" />
            <input type="hidden" name="_email.subject" value="New submission" />
            <input type="hidden" name="_email.from" value={clientName} />
            <input type="hidden" name="_email.template.title" value="false" />
            <input type="hidden" name="_email.template.footer" value="false" />

            <div className="form-group radio-group">
              <p className="para big label">Project type</p>
              <div className="radio-items">
                <label className="input-contain">
                  <input
                    type="radio"
                    name="project"
                    value="Website"
                    disabled={!optionFilter.type[0]}
                    onChange={(e) => handleType(e)}
                    required
                  />
                  <span>Website</span>
                </label>
                <label className="input-contain">
                  <input
                    type="radio"
                    name="project"
                    value="Webapp"
                    disabled={!optionFilter.type[1]}
                    onChange={(e) => handleType(e)}
                  />
                  <span>Webapp</span>
                </label>
                <label className="input-contain">
                  <input
                    type="radio"
                    name="project"
                    value="Others"
                    disabled={!optionFilter.type[2]}
                    onChange={(e) => handleType(e)}
                  />
                  <span>Other</span>
                </label>
              </div>
            </div>

            <div className="form-group radio-group">
              <p className="para big label">Interested in...</p>
              <div className="radio-items">
                <label className="input-contain">
                  <input
                    type="radio"
                    name="service"
                    value="Design"
                    disabled={!optionFilter.service[0]}
                    onChange={(e) => handleService(e)}
                    required
                  />
                  <span>Design</span>
                </label>
                <label className="input-contain">
                  <input
                    type="radio"
                    name="service"
                    value="Development"
                    disabled={!optionFilter.service[1]}
                    onChange={(e) => handleService(e)}
                  />
                  <span>Development</span>
                </label>
                <label className="input-contain">
                  <input
                    type="radio"
                    name="service"
                    value="Design & dev"
                    disabled={!optionFilter.service[2]}
                    onChange={(e) => handleService(e)}
                  />
                  <span>Design &amp; dev</span>
                </label>
              </div>
            </div>

            <div className="form-group radio-group">
              <p className="para big label">Project budget (USD)</p>
              <div className="radio-items">
                <label className="input-contain">
                  <input
                    type="radio"
                    name="budget"
                    value="Less than 2k"
                    disabled={!optionFilter.budget[0]}
                    onChange={(e) => handleBudget(e)}
                    required
                  />
                  <span>&#60; 2k</span>
                </label>
                <label className="input-contain">
                  <input
                    type="radio"
                    name="budget"
                    value="2-5k"
                    disabled={!optionFilter.budget[1]}
                    onChange={(e) => handleBudget(e)}
                  />
                  <span>2-5k</span>
                </label>
                <label className="input-contain">
                  <input
                    type="radio"
                    name="budget"
                    value="5-10k"
                    disabled={!optionFilter.budget[2]}
                    onChange={(e) => handleBudget(e)}
                  />
                  <span>5-10k</span>
                </label>
                <label className="input-contain">
                  <input
                    type="radio"
                    name="budget"
                    value="More than 10k"
                    disabled={!optionFilter.budget[3]}
                    onChange={(e) => handleBudget(e)}
                  />
                  <span>&#62; 10k</span>
                </label>
              </div>
            </div>

            <div className="form-group input-group full-name">
              <label className="input-contain">
                <input
                  type="text"
                  name="name"
                  placeholder=" "
                  onChange={(e) => setClientName(e.target.value)}
                  required
                />
                <span>Your name</span>
              </label>
            </div>

            <div className="form-group input-group">
              <label className="input-contain">
                <input
                  type="email"
                  name="email"
                  placeholder=" "
                  // onChange={(e) => setSubject(e.target.value)}
                  required
                />
                <span>Your email</span>
              </label>
            </div>

            <div className="form-group input-group">
              <label className="input-contain">
                <textarea
                  name="about the project"
                  placeholder=" "
                  rows={3}
                  required
                />
                <span>Tell me about your project</span>
              </label>
            </div>

            <button type="submit">
              {theme === 'theme2' ? (
                <>
                  Send request
                  <span className="arrow">
                    <img src={Arrow} alt="Arrow" />
                  </span>
                </>
              ) : (
                'Send request'
              )}
            </button>
          </form>
        </div>
      </div>
    </section>
  );
}
