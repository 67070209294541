import React from 'react';

export default function TagCarousel({ title }) {
  return (
    <div className="tag-carousel">
      <div className="all-items">
        <span>{title}</span>
        <span>{title}</span>
        <span>{title}</span>
        <span>{title}</span>
        <span>{title}</span>
        <span>{title}</span>
      </div>
    </div>
  );
}
