import React from 'react';
import HeroContact from './HeroContact';
import ContactForm from '../ContactForm';
import Faqs from './Faqs';

export default function ContactContent3({ theme, data }) {
  const { allFaqs } = data.sanityContactpage.faqs;

  return (
    <>
      <HeroContact />
      <ContactForm theme={theme} />
      <Faqs allFaqs={allFaqs} />
    </>
  );
}
