import React from 'react';
import PortableText from '@sanity/block-content-to-react';

const serializers = {
  types: {
    block: ({ children }) => <p className="info para big">{children}</p>,
  },
  marks: {
    link: ({ children, mark }) => (
      <a href={mark.href} className="link" target="_blank" rel="noreferrer">
        {children}
      </a>
    ),
  },
};

export default function Faqs({ allFaqs }) {
  return (
    <section className="faqs-sec">
      <div className="wrapper col-grid">
        <div className="sec-title">
          <div className="title">
            <span>FAQs</span>
          </div>
        </div>

        <div className="all-faqs">
          {allFaqs.map((faq, i) => (
            <div className="single-faq accordion-reveal-1" key={i}>
              <div className="accordion heading">
                <h3 className="title">{faq.que}</h3>
                <div className="plus-icon">
                  <span />
                  <span />
                </div>
              </div>
              <div className="accordion-content">
                <PortableText blocks={faq._rawAns} serializers={serializers} />
              </div>
              <div className="stroke" />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
