import React from 'react';

export default function HeroContact() {
  return (
    <div className="hero t3-contact-hero">
      <div className="heading">
        <div className="wrapper">
          <h1 className="show-up">Contact</h1>
          <p className="para big subhead split-n-wrap">
            Interested in working with me?
            <br />
            Submit your project inquiry using the form below
          </p>
        </div>
      </div>
    </div>
  );
}
