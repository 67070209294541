import React, { useState } from 'react';
import { graphql } from 'gatsby';
import PageHead from '../components/PageHead';
import Layout from '../components/Layout';
import ContactContent1 from '../components/theme1/ContactContent1';
import ContactContent2 from '../components/theme2/ContactContent2';
import ContactContent3 from '../components/theme3/ContactContent3';

export function Head({ location, data }) {
  const { seo } = data.sanityContactpage;

  return (
    <PageHead
      title={seo.title && seo.title}
      description={seo.description && seo.description}
      image={seo.ogImage && seo.ogImage.asset.url}
      location={location}
    />
  );
}

export default function ContactPage({ location, data }) {
  const [activeTheme, setActiveTheme] = useState(() => {
    if (typeof localStorage !== 'undefined') {
      return localStorage.getItem('themeRekh');
    }
    return null;
  });
  const [dummyState, setDummyState] = useState(false); // to trigger loco refresh when theme is changed

  return (
    <Layout
      location={location}
      theme={activeTheme}
      setTheme={setActiveTheme}
      dummyState={dummyState}
      setDummyState={setDummyState}
      footer2
    >
      {activeTheme === 'theme1' && (
        <ContactContent1 theme={activeTheme} data={data} />
      )}
      {activeTheme === 'theme2' && (
        <ContactContent2 theme={activeTheme} data={data} />
      )}
      {activeTheme === 'theme3' && (
        <ContactContent3 theme={activeTheme} data={data} />
      )}
    </Layout>
  );
}

export const query = graphql`
  {
    sanityContactpage {
      faqs {
        allFaqs {
          que
          _rawAns
        }
      }
      seo {
        title
        description
        ogImage {
          asset {
            url
          }
        }
      }
    }
  }
`;
