import React from 'react';

export default function HeroContact() {
  return (
    <div className="hero t2-contact-hero">
      <div className="wrapper">
        <div className="heading">
          <h1 className="split-n-wrap">Contact</h1>
          <p className="subhead para big split-n-wrap">
            Interested in working with me?
            <br />
            Submit your project inquiry using the form below
          </p>
        </div>
      </div>
    </div>
  );
}
