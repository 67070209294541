import React from 'react';
import PortableText from '@sanity/block-content-to-react';

const serializers = {
  types: {
    block: ({ children }) => <p className="info para small">{children}</p>,
  },
  marks: {
    link: ({ children, mark }) => (
      <a href={mark.href} className="link" target="_blank" rel="noreferrer">
        {children}
      </a>
    ),
  },
};

export default function Faqs({ allFaqs }) {
  return (
    <div className="faqs-sec">
      <div className="wrapper">
        <div className="faq-content">
          <h2 className="split-lines">Frequently asked questions</h2>

          <div className="all-faqs stagger-reveal">
            {allFaqs.map((faq, i) => (
              <div className="single-faq" key={i}>
                <div className="accordion heading">
                  <h3 className="title">{faq.que}</h3>
                  <div className="plus-icon">
                    <span />
                    <span />
                  </div>
                </div>
                <div className="accordion-content">
                  <PortableText
                    blocks={faq._rawAns}
                    serializers={serializers}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
